<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { soalService } from "../../../../../helpers/soalService";
import axios from "axios";
import Swal from "sweetalert2";
import permissionService from "../../../../../helpers/permissionService";
import $ from "jquery";

export default {
  page: {
    title: "Soal Menjodohkan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "", name: "-" },
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Soal", name: "-" },
      { width: "100px", label: "Tipe", name: "-" },
      { width: "auto", label: "Gambar", name: "-" },
      { width: "auto", label: "Pertanyaan", name: "pertanyaan" },
      // { width: 'auto', label: 'Jumlah Pertanyaan', name: '-' },
      { width: "200px", label: "Jumlah Jawaban", name: "-" },
      { width: "260px", label: "Aksi", name: "-" },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Soal Menjodohkan",
      items: [
        {
          text: "Master Soal",
          href: "/",
        },
        {
          text: "Soal Menjodohkan",
          active: true,
        },
      ],
      link: process.env.VUE_APP_BACKEND_URL,
      isLoading: true,
      table_data: [],
      columns: columns,
      sortKey: "kompetensi_idkompetensi",
      sortOrders: sortOrders,

      currentTablePage: "",
      tableFilter: {
        status: 1,
        search: "",
        page: 1,
        per_page: 10,
        type_soal: "jodoh",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      idKompetensi: this.$route.params.id,
      kompetensi: {},
      dataPreview: {},
      modalPreview: false,
      modalImport: false,
      listSoalDitandai: [],
      showModalPreview: false,
      previewUrl: "",
      isWriteSetup: 0,
      isReadSetup: 0,
    };
  },
  updated() {
    $("div.pilihan-jawaban > img").addClass("image");
    console.log("updated");
  },
  mounted() {
    this.getKompetensi();
    this.cekPermission();
    this.getData(
      process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/kompetensi/" +
        this.idKompetensi +
        "/soal"
    );
  },
  methods: {
    async cekPermission() {
      const data = await permissionService.getHakAkses(
        "Setup Soal  + All Menu"
      );
      if (data) {
        this.isWriteSetup = data.is_write;
        this.isReadSetup = data.is_read;
      }
      if (this.isWriteSetup != 1) {
        this.columns.pop();
        console.log("this.columns", this.columns);
      }
    },
    async getKompetensi() {
      // let idKompetensi = this.$router.params;
      console.log(this.$route.params.id);
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data.data);

      if (data) {
        this.kompetensi = data;
      }
    },
    async getData(url = "") {
      this.isLoading = true;
      if (url == "") {
        url =
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/kompetensi/" +
          this.idKompetensi +
          "/soal";
      }

      const data = await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.access_token,
            Accept: "Application/json",
          },
          params: this.tableFilter,
        })
        .then((response) => {
          let responseData = response.data;

          this.pagination.lastPage = responseData.data.last_page;
          this.pagination.currentPage = responseData.data.current_page;
          this.pagination.total = responseData.data.total;
          this.pagination.lastPageUrl = responseData.data.last_page_url;
          this.pagination.nextPageUrl = responseData.data.next_page_url;
          this.pagination.prevPageUrl = responseData.data.prev_page_url;
          this.pagination.from = responseData.data.from;
          this.pagination.to = responseData.data.to;
          this.pagination.links = responseData.data.links;
          this.currentTablePage = responseData.data.current_page;

          return responseData.data.data;
        })
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta.code == 403) {
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: errResponse.meta.message,
            });
            this.$router.push({ name: "logout" });
            return false;
          }
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });

      if (data) {
        this.table_data = data;
      }
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    async preview(row) {
      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi +
            "/soal/" +
            row.idsoal,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => response.data.data);
      if (data) {
        this.dataPreview = data;
      }
      Swal.close();
      this.modalPreview = true;
    },
    updateData(row) {
      this.$router.push({
        name: "soal-jodoh-update",
        params: { id: this.idKompetensi, idsoal: row.idsoal },
      });
    },
    deleteData(row) {
      Swal.fire({
        icon: "warning",
        title: "Perhatian!",
        text: "Apakah Anda yakin menghapus data ini ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let url =
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi +
            "/soal";
          let method = row.active_flag == 1 ? "put" : "delete";

          url +=
            row.active_flag == 1 ? "/" + row.idsoal + "/0" : "/" + row.idsoal;

          const data = await axios({
            url: url,
            method: method,
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          })
            .then((response) => response.data.data)
            .catch((error) => {
              console.log(error);
              return false;
            });

          if (data) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Hapus data berhasil",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.getData();
          }
        }
      });
    },
    restore(row) {
      Swal.fire({
        icon: "warning",
        title: "Perhatian!",
        text: "Apakah Anda yakin memulihkan data ini ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let url =
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi +
            "/soal/" +
            row.idsoal +
            "/1";
          let method = "put";

          const data = await axios({
            url: url,
            method: method,
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          })
            .then((response) => response.data.data)
            .catch((error) => {
              console.log(error);
              return false;
            });

          if (data) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Hapus data berhasil",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.getData();
          }
        }
      });
    },
    async deleteMultiple(restore = false) {
      const status = restore && this.tableFilter.status == 0 ? 1 : 0;
      const method = restore || this.tableFilter.status == 1 ? "put" : "delete";
      const transaction = restore ? "memulihkan" : "menghapus";

      await Swal.fire({
        icon: "warning",
        title: "Perhatian!",
        text: `Apakah Anda yakin ${transaction} data ini ?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            iconHtml: '<i class="fas fa-spinner fa-spin"></i>',
            title: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
          });

          const data = await axios({
            method: method,
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/kompetensi/" +
              this.idKompetensi +
              "/soal",
            data: {
              idsoal: this.listSoalDitandai,
              status: status,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          })
            .then((response) => {
              console.log(response);
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                timer: 2000,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Terjadi Kesalahan",
                timer: 2000,
              });
            });
          await this.getData();
          console.log(data);
        }
      });
    },
    async deleteAll() {
      const trans = await soalService.deleteAllSoal(
        this.idKompetensi,
        this.tableFilter.status,
        "Menjodohkan"
      );
      if (trans === 403) {
        await this.$router.push({ name: "logout" });
      }
      await this.getData();
    },
    downloadTemplate() {
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "download-template/soal-jodoh";
      window.open(url, "_blank");
    },
    async importData() {
      this.importFile = this.$refs.file.files[0];

      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi +
            "/soal/import/jodoh",
          {
            file: this.importFile,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Create data berhasil",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "warning",
            title: "Terjadi Kesalahan",
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });

      this.modalImport = false;
      this.$refs.file.files = null;
      this.getData(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/kompetensi/" +
          this.idKompetensi +
          "/soal"
      );
    },
    previewImage(url) {
      this.previewUrl = url;
      this.showModalPreview = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <div>
                  <div class="card-title">Soal Menjodohkan</div>
                  <div>
                    <span class="font-weight-bold">Kompetensi :</span>
                    <br />
                    <strong>{{ kompetensi?.nama_kompetensi || "-" }}</strong>
                  </div>
                  <div>
                    <span class="font-weight-bold">Level Proficiency :</span>
                    <br />
                    <strong>
                      {{ kompetensi?.level_kompetensi?.nama_level || "-" }}
                    </strong>
                  </div>
                </div>
                <div class="d-flex align-items-start gap-2">
                  <router-link
                    class="btn btn-secondary btn-sm"
                    :to="{ name: 'kompetensi' }"
                  >
                    <i class="fa fa-arrow-left me-1"></i>
                    Kembali
                  </router-link>
                  <button
                    class="btn btn-success btn-sm"
                    @click="modalImport = true"
                    v-if="isWriteSetup == 1"
                  >
                    <i class="fa fa-file-excel me-1"></i>
                    Import Soal Kompetensi
                  </button>
                </div>
              </div>
              <div class="col-12">
                <hr />
                <div class="row mb-2">
                  <div class="form-group col-md-2">
                    <label for="statusTable">Status</label>
                    <select
                      class="form-select"
                      id="statusTable"
                      v-model="tableFilter.status"
                      @change="
                        getData();
                        listSoalDitandai = [];
                      "
                    >
                      <option value="1">ENABLE</option>
                      <option value="0">DISABLE</option>
                    </select>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="d-flex align-items-end">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        v-model="tableFilter.per_page"
                        @change="getData()"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div style="max-width: 50%; width: 500px">
                <div
                  class="d-flex justify-content-end gap-2 mb-1"
                  v-if="isWriteSetup == 1"
                >
                  <button class="btn btn-warning btn-sm" @click="deleteAll()">
                    <i class="fa fa-trash me-1"></i>
                    <span v-if="tableFilter.status == 0">
                      Hapus Semua Soal
                    </span>
                    <span v-else> Disable Semua Soal </span>
                  </button>
                  <button
                    class="btn btn-warning btn-sm"
                    v-if="tableFilter.status == 0"
                    :disabled="!listSoalDitandai.length"
                    @click="deleteMultiple(true)"
                  >
                    <i class="fa fa-recycle me-1"></i>
                    Restore Soal Ditandai
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    :disabled="!listSoalDitandai.length"
                    @click="deleteMultiple(false)"
                  >
                    <i class="fa fa-trash me-1"></i>
                    <span v-if="tableFilter.status == 0">
                      Hapus Soal Ditandai
                    </span>
                    <span v-else> Disable Soal Ditandai </span>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                  v-model="tableFilter.search"
                  @input="getData()"
                />
              </div>
            </div>
            <div class="row">
              <Datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="isLoading">
                    <td class="text-center" colspan="99">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="99">
                      Data Tidak Tersedia
                    </td>
                  </tr>
                  <tr v-else v-for="(row, key) in table_data" :key="key">
                    <td class="text-center">
                      <input
                        type="checkbox"
                        class="largerCheckbox"
                        :value="row.idsoal"
                        v-model="listSoalDitandai"
                      />
                    </td>
                    <td>
                      <span v-if="key + 1 == 10">
                        {{ currentTablePage }}0.
                      </span>
                      <span v-else>
                        {{
                          currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                        }}{{ key + 1 }}.
                      </span>
                    </td>
                    <td>{{ row.soal_text || "-" }}</td>
                    <td>{{ row.tipe || "-" }}</td>
                    <td>
                      <div class="container-image" v-if="row.soal_image">
                        <img
                          v-if="row.soal_image"
                          :src="link + row.soal_image"
                          class="image"
                        />
                        <div
                          class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
                        >
                          <button
                            class="btn btn-success btn-sm"
                            @click="previewImage(link + row.soal_image)"
                          >
                            <i class="fa fa-search"></i> Preview
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ row.pertanyaan }}
                    </td>
                    <!-- <td>{{ row.jumlah_pertanyaan || '-' }}</td> -->
                    <td>{{ row.jawaban_count || "-" }}</td>
                    <td>
                      <div
                        class="d-flex justify-content-center gap-2"
                        v-if="isWriteSetup == 1"
                      >
                        <button
                          class="btn btn-success btn-sm"
                          v-if="row.active_flag == 1"
                          @click="preview(row)"
                        >
                          <i class="fa fa-eye me-1"></i>
                          Preview
                        </button>
                        <button
                          class="btn btn-primary btn-sm"
                          v-if="row.active_flag == 1"
                          @click="updateData(row)"
                        >
                          <i class="fa fa-wrench me-1"></i>
                          Setup
                        </button>
                        <button
                          class="btn btn-warning btn-sm"
                          v-if="row.active_flag == 0"
                          @click="restore(row)"
                        >
                          <i class="fa fa-recycle me-1"></i>
                          Restore
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteData(row)"
                        >
                          <i class="fa fa-trash me-1"></i>
                          <span v-if="row.active_flag == 0">Hapus</span>
                          <span v-if="row.active_flag == 1">Disable</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Datatable>
            </div>

            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>

  <b-modal
    v-model="showModalPreview"
    title="Preview Gambar"
    centered
    hide-footer
    size="xl"
  >
    <div class="text-center" style="position: relative; width: 100%">
      <img :src="previewUrl" class="image" />
    </div>
  </b-modal>

  <b-modal v-model="modalPreview" title="Preview Soal" size="xl" hide-footer>
    <div class="row">
      <!--      <div class="col-12">-->
      <!--        <strong>Contoh Soal</strong>-->
      <!--        <div class="card mb-2">-->
      <!--          <div class="card-body bg-secondary bg-opacity-10 p-2">-->
      <!--            {{ dataPreview.soal_text }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-12">
        <div class="row">
          <div class="col-8">
            <table class="table table-bordered">
              <thead class="bg-dark text-white">
                <tr>
                  <th width="50px">No</th>
                  <th>Pertanyaan</th>
                  <th width="80px">No Urut</th>
                  <!-- <th width="100px">Timer</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ "1" }}</td>
                  <td>
                    {{ dataPreview.pertanyaan }}
                  </td>
                  <td>{{ dataPreview.nomor_urut }}</td>
                  <!-- <td>{{ dataPreview.timer }}</td> -->
                </tr>
              </tbody>
            </table>
            <div
              class="container-image-soal"
              v-if="dataPreview.soal_image"
            >
              <img
                v-if="dataPreview.soal_image"
                :src="link + dataPreview.soal_image"
                class="image"
              />
            </div>
          </div>
          <div class="col-4">
            <ul class="list-group list-group-sm">
              <li
                class="list-group-item bg-dark text-white"
                aria-current="true"
              >
                Jawaban
              </li>
              <li
                class="list-group-item"
                v-for="jawaban in dataPreview.jawaban"
                :key="jawaban.idjawaban"
              >
                <div
                  class="container-image-soal pilihan-jawaban"
                  v-html="jawaban.jawaban"
                ></div>
                <span class="float-end text-success" v-if="jawaban.skor > 0">
                  <i class="fa fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
  <b-modal
    v-model="modalImport"
    title="Import Soal Menjodohkan"
    size="md"
    hide-footer
  >
    <div class="row">
      <div class="col-12 mb-2">
        <button class="btn btn-success btn-sm" @click="downloadTemplate()">
          <i class="fa fa-download me-1"></i> Download Template
        </button>
      </div>
      <div class="col-12 mb-2">
        <div class="form-group">
          <label for="file_import_excel">File Excel</label>
          <input
            type="file"
            id="file_import_excel"
            class="form-control"
            ref="file"
            accept=".xlsx,.xls"
            mime
          />
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary btn-sm float-end" @click="importData()">
          <i class="fa fa-save me-1"></i> Save
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
input.largerCheckbox {
  width: 20px;
  height: 20px;
}
</style>
